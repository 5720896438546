import React from 'react';
import './HeroSection.css';
import bgImage from '../../assets/hero image.jpg';
import phoneImage from '../../assets/app ss at hero section.png'; 

const HeroSection = () => {
  return (
    <div className="hero-section">

      <div className="bg-image"></div>
      
      <div className="content">
        <h1>
          Full stack recruitment platform <br />
          helping <span>manufacturers</span> fulfill <br />
          worker related needs
        </h1>
      </div>
      
      <div className="mockup">
        <img className='mockup-img' src={phoneImage} alt="Phone mockup " />
      </div>
    </div>
  );
};

export default HeroSection;
