import React, { useState, useEffect } from 'react';
import './Benefits.css';
import mf1 from "../../assets/manufacturer benefits 1.jpg";
import mf2 from "../../assets/manufacturer benefits 2.jpg";
import mf3 from "../../assets/manufacturer benefits 3.jpg";
import wf1 from "../../assets/worker benefits 1.jpg";
import wf2 from "../../assets/worker benefits 2.jpg";
import wf3 from "../../assets/worker benefits 3.jpg";

const Benefits = () => {
  const [currentManufacturerIndex, setCurrentManufacturerIndex] = useState(0);
  const [currentWorkerIndex, setCurrentWorkerIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 480);

  const manufacturers = [
    { img: mf1, text: "Skill specific talent pool from various Institutes" },
    { img: mf2, text: "Seamless sourcing, verification, and onboarding" },
    { img: mf3, text: "Guaranteed candidate replacement with periodic payout" },
  ];

  const workers = [
    { img: wf1, text: "Industry Specific Jobs" },
    { img: wf2, text: "Job opportunities from verified employers" },
    { img: wf3, text: "Get a Job at No Charges" },
  ];

  // Handle screen resize to detect mobile
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 480);
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  // Infinite scroll helper
  const getNextIndex = (currentIndex, length) => (currentIndex + 1) % length;
  const getPrevIndex = (currentIndex, length) =>
    (currentIndex - 1 + length) % length;

  const handlePrev = () => {
    setCurrentManufacturerIndex((prevIndex) =>
      getPrevIndex(prevIndex, manufacturers.length)
    );
    setCurrentWorkerIndex((prevIndex) =>
      getPrevIndex(prevIndex, workers.length)
    );
  };

  const handleNext = () => {
    setCurrentManufacturerIndex((prevIndex) =>
      getNextIndex(prevIndex, manufacturers.length)
    );
    setCurrentWorkerIndex((prevIndex) =>
      getNextIndex(prevIndex, workers.length)
    );
  };

  // Set up auto-change for mobile images
  useEffect(() => {
    let intervalId;
    if (isMobile) {
      intervalId = setInterval(() => {
        setCurrentManufacturerIndex((prevIndex) =>
          getNextIndex(prevIndex, manufacturers.length)
        );
        setCurrentWorkerIndex((prevIndex) =>
          getNextIndex(prevIndex, workers.length)
        );
      }, 5000); // Change every 5 seconds
    }
    
    // Clean up interval on component unmount or when leaving mobile view
    return () => clearInterval(intervalId);
  }, [isMobile]);

  return (
    <section className="benefits-section">
      <h2 className="benefits-heading">Benefits</h2>

      {/* Mobile View */}
      {isMobile ? (
  <div className="mobile-view">
    {/* Manufacturer Section */}
    <div className="mobile-card manufacturer-card">
      <h3 className="category-heading">Manufacturers</h3>
      <div className="slider-container">
        <button
          className="slider-btn prev-btn"
          onClick={() =>
            setCurrentManufacturerIndex((prevIndex) =>
              getPrevIndex(prevIndex, manufacturers.length)
            )
          }
        >
          &#8249;
        </button>
        <div className="card">
          <div className="imgBox">
            <img
              className="imgB"
              src={manufacturers[currentManufacturerIndex].img}
              alt={manufacturers[currentManufacturerIndex].text}
            />
          </div>
          <p className="text-mobile">
            {manufacturers[currentManufacturerIndex].text}
          </p>
        </div>
        <button
          className="slider-btn next-btn"
          onClick={() =>
            setCurrentManufacturerIndex((prevIndex) =>
              getNextIndex(prevIndex, manufacturers.length)
            )
          }
        >
          &#8250;
        </button>
      </div>
    </div>

    {/* Worker Section */}
    <div className="mobile-card worker-card">
      <h3 className="category-heading">Workers</h3>
      <div className="slider-container">
        <button
          className="slider-btn prev-btn"
          onClick={() =>
            setCurrentWorkerIndex((prevIndex) =>
              getPrevIndex(prevIndex, workers.length)
            )
          }
        >
          &#8249;
        </button>
        <div className="card cardB">
          <div className="imgBox imgBox1">
            <img
              className="imgB"
              src={workers[currentWorkerIndex].img}
              alt={workers[currentWorkerIndex].text}
            />
          </div>
          <p className="text-mobile">
            {workers[currentWorkerIndex].text}
          </p>
        </div>
        <button
          className="slider-btn next-btn"
          onClick={() =>
            setCurrentWorkerIndex((prevIndex) =>
              getNextIndex(prevIndex, workers.length)
            )
          }
        >
          &#8250;
        </button>
      </div>
    </div>
  </div>
): (
        /* Desktop View */
        <div className="desktop-view">
          <div className="benefits-category">
            <h3 className="category-heading">Manufacturers</h3>
            <div className="card-container desktop">
              {manufacturers.map((card, idx) => (
                <div className="card" key={idx}>
                  <div className="imgBox">
                    <img className="imgB" src={card.img} alt={card.text} />
                  </div>
                  <p>{card.text}</p>
                </div>
              ))}
            </div>
          </div>

          <div className="benefits-category">
            <h3 className="category-heading">Workers</h3>
            <div className="card-container desktop">
              {workers.map((card, idx) => (
                <div className="card cardB" key={idx}>
                  <div className="imgBox">
                    <img className="imgB" src={card.img} alt={card.text} />
                  </div>
                  <p>{card.text}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default Benefits;
