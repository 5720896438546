import React from 'react';
import { useNavigate } from 'react-router-dom';
import logo from "../../assets/Factory Kaam (2).png";
import './Header.css';

const Header = () => {
  const navigate = useNavigate();

  const handleLogoClick = () => {
    navigate('/');
    window.scrollTo(0, 0);
  };

  return (
    <div className="header-container">
      <div className="logo-section" onClick={handleLogoClick}>
        <img src={logo} alt="factorykaam logo" className="logo" />
        <div className="company-text">
          <h1>factorykaam</h1>
          <p>Nirman se Networking tak</p>
        </div>
      </div>
      <div className="contact-section">
        <div className="contact-box">
          <a href="tel:9818402777" className="contact-item">
            <span className="material-icons">phone</span> 98184-02777
          </a>
        </div>
        <div className="contact-box">
          <a href="mailto:kshitij@factorykaam.com" className="contact-item contact-item2">
            <span className="material-icons">email</span> kshitij@factorykaam.com
          </a>
        </div>
      </div>
    </div>
  );
};

export default Header;