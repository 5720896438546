import React from 'react';
import './About.css';
import download from "../../assets/download section.png";
import GooglePlay from "../../assets/google play.png"

const About = () => {
    return (
        <div className="about-container">
            <h2 className="about-header">About factorykaam</h2>
            <p className='about-para'>UDYOGTECH VENTURES PRIVATE LIMITED</p>
            <div className="about-content">
                {/* Left Section */}
                <div className="about-left">
                    <h3>Our Mission</h3>
                    <p>
                        We intend empowering manufacturers by helping them recruit factory workers and streamline hiring processes and knowledge sharing.
                    </p>
                    <h3 className='our-vision'>Our Vision</h3>
                    <p>
                        Our vision is to support manufacturers through various services such that they can contribute to India becoming a leading global manufacturing nation by 2035.
                    </p>
                </div>

                {/* Middle Section */}
                <div className='about-flex'>
                <div className="about-middle">
                    <img src={download} alt="factorykaam illustration" className="middle-image" />
                </div>

                {/* Right Section */}
                <div className="about-right">
                <div className='about-img'>
                    <h3 className='about-right-h'>Download factorykaam App</h3>
                    <a
                        href="https://play.google.com/store/apps/details?id=com.factoryKaam&hl=en"
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <div className="app-image"></div>
                    </a>
                    </div>
                    <div className='about-visit'>
                        <h3 className='about-visitUs'>Visit us at:</h3>
                        <p className='about-address'>Unit 205, 2nd Floor, Padma Tower2, <br/>
                            Rajendra Place, Delhi, India, 110008</p>
                    </div>
                </div>
                </div>
            </div>
        </div>
    );
};

export default About;