import React from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Header from './Componnets/Header/Header'
import HeroSection from './Componnets/HeroSection/HeroSection'
import HomePage from './Page/HomePage'
import Footer from './Componnets/Footer/Footer';
import PrivacyPolicy from './Componnets/PrivacyPolicy/PrivacyPolicy';

const App = () => {
  return (
    <Router>
    <div>
      <Header />
      <Routes>
      <Route path="/" element={<HomePage />} />
      <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      </Routes>
      <Footer />
    </div>
    </Router>
  )
}

export default App
