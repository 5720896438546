import React from 'react'
import HeroSection from '../Componnets/HeroSection/HeroSection'
import Clients from '../Componnets/Clients/Clients'
import Benefits from '../Componnets/Benefits/Benefits'
import Why from '../Componnets/Why/Why'
import About from '../Componnets/About/About'

const HomePage = () => {
  return (
    <div>
      <HeroSection />
      <Why />
      <Clients />
      <Benefits />
      <About />
    </div>
  )
}

export default HomePage
