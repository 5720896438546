import React from 'react';
import "./Why.css";
import image1 from "../../assets/curated platform for hiring.jpeg";
import image2 from "../../assets/tie up with institutes.png";
import image3 from "../../assets/tech driven.png";

const Why = () => {
    return (
        <div className="why">
            <div className="why-content">
                <h2 className="why-title">Why factorykaam</h2> 
                <div className="why-boxes">
                    <div className="why-box">
                        <img className="why-img why-sp2" src={image1} alt="Feature 1" />
                        <div className="why-text">
                            <h3>Curated platform for factory hiring</h3>
                            <p>Focuses on connecting factory workers with factory owners</p>
                        </div>
                    </div>
                    <div className="why-box">
                        <img src={image2} alt="Feature 2" />
                        <div className="why-text">
                            <h3>Access to vast Talent Pool</h3>
                            <p>Get access to 2500+ ITIs and 1000+ Polytechnic Institutes</p>
                        </div>
                    </div>
                    <div className="why-box">
                        <img className="why-img why-sp1" src={image3} alt="Feature 3" />
                        <div className="why-text">
                            <h3>Tech Driven</h3>
                            <p>Mobile App & Recruitment Dashboard for simplified hiring</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Why;
