import React from "react";
import Slider from "react-slick";
import hilux from "../../assets/hilux-removebg-preview.png";
import fastrack from "../../assets/fastrackpolyfab_logo-removebg-preview.png";
import skh from "../../assets/skh_emerge_logo-removebg-preview.png";
import vimal from "../../assets/vimal_plastics-removebg-preview (1).png";
import Diamond from "../../assets/diamond-exports-logo-120x120.png";
import "./Clients.css";

const clients = [
  { id: 1, imgSrc: hilux, name: "Hilux Automotive" },
  { id: 2, imgSrc: fastrack, name: "Fastrack Polyfab" },
  { id: 3, imgSrc: skh, name: "SKH Group" },
  { id: 4, imgSrc: vimal, name: "Vimal Plastics" },
  { id: 5, imgSrc: Diamond, name: "Diamond Exports" },
];

const Clients = () => {
  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 1000, // Default speed for larger screens
    autoplay: true,
    autoplaySpeed: 1000,
    cssEase: "linear",
    pauseOnHover: true,
    slidesToShow: 4,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1150,
        settings: {
          slidesToShow: 3,
          speed: 1200,
          autoplaySpeed: 1200, 
        },
      },
      {
        breakpoint: 964,
        settings: {
          slidesToShow: 2,
          autoplaySpeed:1500,
          speed: 1500, 
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          autoplaySpeed: 3800,
          speed: 3800, 
        },
      },
    ],
  };


  return (
    <div className="clients-section">
      <h2 className="clients-title">Our Clients</h2>
      <div className="client-slider">
        <Slider {...sliderSettings} className="clients-slider">
          {clients.map((client) => (
            <div key={client.id} className="client">
              <div className="client-logo">
                <img src={client.imgSrc} alt={client.name} className="client-img" />
                <p className="client-name">{client.name}</p>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default Clients;
