import React from "react";
import "./Footer.css";
import logo from "../../assets/Factory Kaam (2).png"; 
import { useNavigate } from "react-router-dom";

const Footer = () => {
    const navigate = useNavigate();

    const handleLogoClick = () => {
        window.scrollTo(0, 0); 
        navigate('/'); 
    };

    const handlePrivacyPolicyClick = () => {
        window.scrollTo(0, 0); 
        navigate('/privacy-policy'); 
    };

    return (
        <footer className="footer">
            <div className="footer-left" onClick={handleLogoClick}>
                <img src={logo} alt="factorykaam logo" className="footer-logo" />
            </div>

            <div className="footer-center">
                <h3 className="footer-title">factorykaam</h3>
                <p className="footer-subtitle">Nirman se Networking tak</p>
                <address className="footer-address">
                    205, 2nd Floor, Padma Tower II <br />
                    Rajendra Place, New Delhi <br />
                    110008
                </address>
                <p
                    className="footer-privacy"
                    onClick={handlePrivacyPolicyClick}
                >
                    Privacy Policy
                </p>
            </div>

            <div className="footer-right">
                <div className="footer-contact-section">
                    <div className="footer-contact-box footer-contact">
                        <a href="tel:9818402777" className="footer-contact-item">
                            <span className="material-icons">phone</span> 98184-02777
                        </a>
                    </div>
                    <div className="footer-contact-box">
                        <a
                            href="mailto:kshitij@factorykaam.com"
                            className="footer-contact-item footer-contact-item2"
                        >
                            <span className="material-icons">email</span> kshitij@factorykaam.com
                        </a>
                    </div>
                </div>
                <p className="footer-copyright">
                <span class="line1">All rights reserved © 2024 </span>
                <span class="line2">UDYOGTECH VENTURES PRIVATE LIMITED</span>
                </p>
            </div>
        </footer>
    );
};

export default Footer;